import * as React from "react";
import Header from '../components/shared/header';
import { Helmet } from "react-helmet"
import Footer from '../components/shared/footer';
import NotFoundIcon from "../../assets/svg/not-found.svg";
import './404.scss';
import { Link } from 'gatsby';

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8"/>
        <title>Marble</title>
      </Helmet>

      <Header />

      <main className="not-found">
          <img src={NotFoundIcon} alt="Not found" />
          <h1>Page not found</h1>
          <p>We couldn't find what you were looking for.</p>
          <Link to="/">Go back to homepage</Link>
      </main>

      <Footer />
    </>
  )
}

export default NotFoundPage
